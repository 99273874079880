import { debounce } from "lodash";
import { startTransition, useCallback, useEffect, useState } from "react";

const useIsHttpRequestsDone = () => {
  const [isHttpRequestsDone, setIsHttpRequestsDone] = useState(false);

  const debounceSetIsGetResourcesDone = useCallback(
    debounce(() => {
      startTransition(() => {
        setIsHttpRequestsDone(true);
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (!("PerformanceObserver" in window)) {
      console.warn("PerformanceObserver is not supported in this browser.");
      setIsHttpRequestsDone(true);
      return;
    }

    if (isHttpRequestsDone) {
      // Turn off observer when isGetResourcesDone is true
      return;
    }

    const observer = new PerformanceObserver(list => {
      const entries = list.getEntries();

      const endedChunkEntries = entries.filter(
        entry =>
          entry.initiatorType === "xmlhttprequest" &&
          entry.entryType === "resource" &&
          entry.responseEnd
      );

      if (endedChunkEntries.length > 0) {
        debounceSetIsGetResourcesDone();
      }
    });

    observer.observe({ entryTypes: ["resource"] });

    return () => {
      debounceSetIsGetResourcesDone.cancel();
      observer.disconnect();
    };
  }, [debounceSetIsGetResourcesDone, isHttpRequestsDone]);

  return { isHttpRequestsDone };
};

export default useIsHttpRequestsDone;
