import autoBind from "auto-bind";
import { isEmpty, isEqual, uniqWith } from "lodash";
import PropTypes from "prop-types";
import qs from "qs";
import React from "react";

import { store } from "../../redux/configureStore";
import { getIsWeb } from "../platformHelper";
import ApiHelper from "./CoreHelpers/ApiHelper";
import * as DataInterceptors from "./DataInterceptor/";
import { FetchTabWithOptionsParams } from "./DataInterceptor/DefaultDataInterceptor";

export default class UniversalTableCore extends React.Component {
  static defaultPagination = {
    current: 1,
    rowsPerPage: 20
  };

  constructor(props) {
    super(props);

    const { defaultSettings } = props;

    const DataInterceptorClass =
      DataInterceptors[
        `${props.type}${UniversalTableCore.dataInterceptorSuffix}`
      ];

    this.dataInterceptor = new DataInterceptorClass(
      props.locale,
      props.authToken,
      props.options
    );

    autoBind.react(this);

    let excludedSettings = [];

    ["filters", "search"].forEach(key => {
      if (defaultSettings[key]) {
        excludedSettings.push(...Object.keys(defaultSettings[key]));
      }
    });

    this.state = {
      UniversalTable: {
        config: null,
        data: null,
        currentTab: null,
        dataLoading: true,
        configLoading: true,
        isFetchingNextPage: false,
        pagination: { ...UniversalTableCore.defaultPagination },
        sortBy: defaultSettings.sortBy || {
          field: null,
          direction: "asc"
        },
        search: {},
        filters: defaultSettings.filters || {},
        excludedSettings,
        columnsFontSize: {},
        getTableState: this.getTableState,
        getExcludedSettings: this.getExcludedSettings,
        setTableState: this.setTableState,
        setSorting: this.setSorting,
        setSearch: this.setSearch,
        setFilters: this.setFilters,
        getColumns: this.getColumns,
        getAllAvailableColumns: this.getAllAvailableColumns,
        reFetchAfterSettingsChange: this.fetchData,
        setPaginationState: this.setPaginationState
      }
    };
  }

  static propTypes = {
    type: PropTypes.oneOf(["Default", "TransactionHistory"]),
    refreshOn: PropTypes.arrayOf(PropTypes.string),
    useUrlSettings: PropTypes.bool,
    usePagination: PropTypes.bool,
    columnLengthSmall: PropTypes.number,
    columnLengthExtraSmall: PropTypes.number,
    defaultSettings: PropTypes.shape({
      search: PropTypes.object,
      filters: PropTypes.object
    })
  };

  static defaultProps = {
    type: "Default",
    useUrlSettings: false,
    usePagination: true,
    columnLengthSmall: 13,
    columnLengthExtraSmall: 20,
    defaultSettings: {}
  };

  static dataInterceptorSuffix = "DataInterceptor";

  async componentDidMount(): void {
    const { refreshOn } = this.props;
    await this.initialize();
    await this.fetchData();

    if (refreshOn && refreshOn.length) {
      this.unsubscribeFromReduxActions = store.subscribe(() => {
        const lastAction = store.getState().lastAction;

        if (refreshOn.indexOf(lastAction.type) !== -1) {
          this.refresh();
        }
      });
    }
  }

  componentWillUnmount(): void {
    if (this.unsubscribeFromReduxActions) {
      this.unsubscribeFromReduxActions();
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<P>,
    nextState: Readonly<S>,
    nextContext: any
  ): boolean {
    return (
      !this.state.data ||
      this.state.data.next !== nextState.data.next ||
      this.state.dataLoading !== nextState.dataLoading ||
      this.state.configLoading !== nextState.configLoading ||
      this.state.isFetchingNextPage !== nextState.isFetchingNextPage
    );
  }

  async initialize() {
    const { resourceId, defaultSettings, fetchConfigParams } = this.props;

    let config = await this.dataInterceptor.fetchConfig(
      resourceId,
      defaultSettings,
      fetchConfigParams
    );

    config = this.transformConfig(config);

    let currentTab = config.tabs.length
      ? config.tabs.find(tab => tab.is_default) || config.tabs[0]
      : null;

    let settingsFromUrl = {};

    if (this.shouldUseUrlSettings()) {
      settingsFromUrl = this.getSettingsFromUrl();
    }

    if (settingsFromUrl.currentTabId) {
      settingsFromUrl.currentTabId = Number(settingsFromUrl.currentTabId);
      const tabFromUrl = config.tabs.find(
        tab => tab.id === settingsFromUrl.currentTabId
      );

      if (config.tabs.length && tabFromUrl) {
        currentTab = tabFromUrl;
      }
    }

    const savedSettings = this.getSavedSettings(
      this.createCacheKey({ tableId: config.id, tableName: config.name })
    );

    this.setTableState({
      config,
      currentTab: currentTab,
      currentTabId: currentTab.id,
      configLoading: false,
      ...settingsFromUrl,
      ...savedSettings
    });
  }

  async fetchData(
    tabId,
    callback = () => {},
    futureState = null,
    params: FetchTabWithOptionsParams
  ) {
    const { columnLengthSmall, columnLengthExtraSmall } = this.props;

    this.setTableState({
      dataLoading: true
    });

    const { resourceId, fetchDataParams } = this.props;

    const { currentTabId } = futureState || this.getTableState();

    const initialData = await this.dataInterceptor.fetchTabWithOptions(
      resourceId,
      tabId || currentTabId,
      futureState || this.getTableState(),
      params || fetchDataParams
    );

    let columnsFontSize = {};

    if (
      getIsWeb() &&
      initialData &&
      initialData.results &&
      initialData.results.length
    ) {
      const columns = this.getAllAvailableColumns();
      columns
        .filter(
          column =>
            column.values_type === "string" &&
            Object.keys(initialData.results[0]).indexOf(
              column.attribute_name
            ) !== -1
        )
        .forEach(column => {
          for (let row of initialData.results) {
            const cell = row[column.attribute_name];

            if (typeof cell === "undefined" || cell === null) {
              continue;
            }

            if (cell.length >= columnLengthExtraSmall) {
              columnsFontSize[column.attribute_name] = "extra-small";
              break;
            } else if (cell.length >= columnLengthSmall) {
              columnsFontSize[column.attribute_name] = "small";
            }
          }
        });
    }

    this.setTableState({
      data: initialData,
      dataLoading: false,
      columnsFontSize
    });

    callback();
  }

  async fetchNextPage() {
    this.setIsFetchingNextPage(true);
    const {
      data: { next: nextPageUrl }
    } = this.getTableState();

    const nextPageData = await this.dataInterceptor.fetchNextPage(nextPageUrl);
    const { results, next } = nextPageData;

    this.setTableState(prevState => ({
      data: {
        results: [...prevState.data.results, ...results],
        next
      }
    }));

    this.setIsFetchingNextPage(false);
  }

  async refresh() {
    this.dataInterceptor.clearCache();
    await this.fetchData();
  }

  getColumns() {
    const columnPairs = this.getColumnPairs();

    if (!columnPairs.length) {
      return [];
    }

    let columns = [];

    columnPairs.forEach(columnPair => {
      columns.push(columnPair.csv_data_column_first);
      columns.push(columnPair.csv_data_column_second);
    });

    return columns.filter(column => !!column);
  }

  getAllAvailableColumns() {
    const { config } = this.getTableState();

    let columns = [];

    if (!config) {
      return columns;
    }

    config.tabs.forEach(tab => {
      tab.column_pairs.forEach(columnPair => {
        ["csv_data_column_first", "csv_data_column_second"].map(columnKey => {
          const column = columnPair[columnKey];
          if (column) {
            columns.push(column);
          }
        });
      });
    });

    return uniqWith(columns, isEqual);
  }

  getColumnPairs() {
    const { currentTab } = this.getTableState();

    if (!currentTab) {
      return [];
    }

    return currentTab.column_pairs;
  }

  setIsFetchingNextPage(isFetchingNextPage) {
    this.setTableState({
      isFetchingNextPage
    });
  }

  setTableState(newState, callback) {
    this.setState(
      state => ({
        UniversalTable: {
          ...state.UniversalTable,
          ...(typeof newState === "function"
            ? newState(state.UniversalTable)
            : newState)
        }
      }),
      (...params) => {
        if (callback) {
          callback(...params);
        }

        if (this.canUpdateSavedSettings()) {
          this.updateSavedSettings(
            this.createCacheKey({
              tableId: this.state.UniversalTable?.config?.id,
              tableName: this.state.UniversalTable?.config?.name
            }),
            {
              sortBy: this.state.UniversalTable.sortBy,
              pagination: this.state.UniversalTable.pagination,
              search: this.state.UniversalTable.search,
              filters: this.state.UniversalTable.filters,
              currentTabId: this.state.UniversalTable.currentTabId
            }
          );
        }

        this.appendSettingsToUrl();
      }
    );
  }

  canUpdateSavedSettings() {
    const { search, filters, sortBy, pagination } = this.getTableState();

    if (
      !isEmpty(search) ||
      !isEmpty(filters) ||
      !isEqual(pagination, UniversalTableCore.defaultPagination) ||
      sortBy.field !== null
    ) {
      return true;
    }
  }

  appendSettingsToUrl() {
    if (!this.shouldUseUrlSettings()) {
      return;
    }

    const {
      currentTabId,
      search,
      filters,
      sortBy,
      pagination
    } = this.getTableState();
    const baseUrl = window.location.origin + window.location.pathname;

    let settingsToAppend = {
      currentTabId
    };

    if (!isEmpty(search)) {
      settingsToAppend = {
        ...settingsToAppend,
        search
      };
    }

    if (!isEmpty(filters)) {
      settingsToAppend = {
        ...settingsToAppend,
        filters
      };
    }

    if (!isEqual(pagination, UniversalTableCore.defaultPagination)) {
      settingsToAppend = {
        ...settingsToAppend,
        pagination
      };
    }

    if (sortBy.field !== null) {
      settingsToAppend = {
        ...settingsToAppend,
        sortBy
      };
    }

    history.replaceState(
      null,
      "",
      baseUrl + "?" + qs.stringify(settingsToAppend)
    );
  }

  getSettingsFromUrl() {
    let settingsFromUrl = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    if (settingsFromUrl.pagination) {
      settingsFromUrl.pagination.current = Number(
        settingsFromUrl.pagination.current
      );
      settingsFromUrl.pagination.rowsPerPage = Number(
        settingsFromUrl.pagination.rowsPerPage
      );
    }

    if (!isEmpty(settingsFromUrl.filters)) {
      Object.keys(settingsFromUrl.filters).forEach(filterKey => {
        let filter = settingsFromUrl.filters[filterKey];

        ["min", "max"].forEach(key => {
          if (typeof filter[key] !== "undefined") {
            if (filter[key] === "") {
              filter[key] = null;
            } else if (!isNaN(filter[key])) {
              filter[key] = Number(filter[key]);
            }
          }
        });
      });
    }

    return settingsFromUrl;
  }

  shouldUseUrlSettings() {
    return this.props.useUrlSettings && getIsWeb();
  }

  getSavedSettings(cacheKey) {
    throw new Error("Not implemented");
  }

  saveSettings(cacheKey, settings) {
    throw new Error("Not implemented");
  }

  updateSavedSettings(cacheKey, settings) {
    throw new Error("Not implemented");
  }

  createCacheKey({ tableId, tableName }) {
    return `${tableId}${tableName}`;
  }

  setSorting(newSorting) {
    this.setTableState(
      oldState => ({
        sortBy: newSorting,
        pagination: {
          ...oldState.pagination,
          current: 1
        }
      }),
      () => {
        this.fetchData().catch(() => {});
      }
    );
  }

  setSearch(newSearch) {
    this.setTableState(
      oldState => ({
        search: newSearch,
        pagination: {
          ...oldState.pagination,
          current: 1
        }
      }),
      () => {
        this.fetchData().catch(() => {});
      }
    );
  }

  setFilters(newFilters) {
    this.setTableState(
      oldState => ({
        filters: newFilters,
        pagination: {
          ...oldState.pagination,
          current: 1
        }
      }),
      () => {
        this.fetchData().catch(() => {});
      }
    );
  }

  setPaginationState(newPagination) {
    this.setTableState({
      dataLoading: true
    });

    setTimeout(() => {
      this.fetchData(
        null,
        () => {
          this.setTableState(state => ({
            pagination: {
              ...state.pagination,
              ...newPagination
            }
          }));
        },
        {
          ...this.getTableState(),
          pagination: {
            ...this.getTableState().pagination,
            ...newPagination
          }
        }
      );
    }, 100);
  }

  setTab(id, initializing = false) {
    const { config } = this.getTableState();

    const newTab = config.tabs.find(tab => tab.id === id);

    this.setTableState({
      dataLoading: true,
      sortBy: {
        field: null,
        direction: "asc"
      }
    });

    setTimeout(
      () => {
        this.fetchData(id, () => {
          this.setTableState({
            currentTabId: id,
            currentTab: newTab
          });
        }).catch(() => {});
      },
      !initializing ? 500 : 0
    );
  }

  getTableState() {
    return this.state.UniversalTable;
  }

  getExcludedSettings() {
    return this.getTableState().excludedSettings;
  }

  transformConfig(config) {
    const { transformTypes } = this.props;

    config.tabs.forEach(tab => {
      tab.column_pairs.forEach(columnPair => {
        ["csv_data_column_first", "csv_data_column_second"].forEach(column => {
          if (columnPair[column]) {
            const valuesType = columnPair[column].values_type;
            const filterData = columnPair[column].filter_data;

            if (transformTypes && transformTypes[valuesType]) {
              columnPair[column].values_type = transformTypes[valuesType];
            }

            if (
              ApiHelper.getIsFilterableByRange(columnPair[column]) &&
              !isNaN(filterData.min) &&
              !isNaN(filterData.max)
            ) {
              columnPair[column].filter_data = {
                min: Math.floor(filterData.min * 100) / 100,
                max: Math.ceil(filterData.max * 100) / 100
              };
            }
          }
        });
      });
    });

    return config;
  }
}
