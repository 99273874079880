import "../styles/default.scss";

import autoBind from "auto-bind";
import classNames from "classnames";
import { isEmpty } from "lodash";
import qs from "qs";
import React from "react";
import { Redirect } from "react-router";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import paylaneLogo from "../../../../../assets/images/payments/paylane.png";
import payuLogo from "../../../../../assets/images/payments/payu.jpg";
import stripeLogo from "../../../../../assets/images/payments/stripe.png";
import Price from "../../../../../common/Price";
import config from "../../../../../config";
import withAddAlert from "../../../../../hocs/withAddAlert";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import withPaymentDetails from "../../../../../hocs/withPaymentDetails";
import withPaymentForm from "../../../../../hocs/withPaymentForm";
import withUserData from "../../../../../hocs/withUserData";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import ContentSection from "../../../common/ContentSection";
import Loader from "../../../common/Loader";
import PageTitle from "../../../common/PageTitle";
import SquaberLink from "../../../common/SquaberLink";
import Confirmation from "../components/Confirmation";
import getDiscountOffer from "../utils/getDiscountOffer";
import getOfferDescription from "../utils/getOfferDescription";
import hasDescription from "../utils/hasDescription";
import hasDiscount from "../utils/hasDiscount";

export interface PaymentMethodMetaData {
  paymentMethods: Array<string>;
  subscriptionId: Number | null;
}

export const PAYMENT_METHODS = {
  PAYLANE: "PayLane",
  PAYPAL: "PayPal",
  PAYU: "PayU",
  VISA: "VISA",
  STRIPE: "Stripe",
  STRIPE_SUBSCRIPTION: "Stripe Subscription"
};

export class PaymentForm extends React.Component {
  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  state = {
    currentPaymentMethod: {
      name: PAYMENT_METHODS.PAYU,
      image: payuLogo,
      alias: "PAYU"
    },
    paymentMethod: PAYMENT_METHODS.PAYU,
    paymentMethodAlias: "PAYU",
    cardModel: {
      card_code: "",
      card_number: "",
      expiration_month: "01",
      expiration_year: "18",
      name_on_card: ""
    }
  };

  paymentMethods = [
    {
      name: PAYMENT_METHODS.PAYLANE,
      image: paylaneLogo,
      alias: "PAYLANE"
    },
    {
      name: PAYMENT_METHODS.PAYU,
      image: payuLogo,
      alias: "PAYU"
    },
    {
      name: PAYMENT_METHODS.STRIPE,
      image: stripeLogo,
      alias: "Stripe"
    },
    {
      name: PAYMENT_METHODS.STRIPE_SUBSCRIPTION,
      image: stripeLogo,
      alias: "Stripe Subscription"
    }
  ];

  componentDidMount() {
    this.props.fetchPaymentDetails();
  }

  setPaymentMethod(method) {
    this.setState({
      paymentMethod: method.name,
      paymentMethodAlias: method.alias,
      currentPaymentMethod: method
    });
  }

  setCardModelValue(key, value) {
    const { cardModel } = this.state;

    cardModel[key] = value;

    this.setState({ cardModel });
  }

  getUserIsLoggedIn() {
    const {
      userData,
      location: { search }
    } = this.props;

    const searchParams = qs.parse(search, { ignoreQueryPrefix: true });

    return !!userData || !!searchParams.auth_token_for_login;
  }

  getDiscountSummary(selectedOffer) {
    const { translate, paymentDetails } = this.props;
    const { currency } = paymentDetails;

    const discountedOffer = getDiscountOffer({
      ...selectedOffer
    });

    return (
      <>
        <span className="label">{translate("Discount")}:</span>
        <span className="offer-detail">
          -
          <Price
            value={Number(discountedOffer.usedAffiliatePoints)}
            currency={currency}
          />
        </span>
        <span className="label">{`${translate("Price after discount")}`}:</span>
        <span className="offer-detail">
          <Price
            value={Number(discountedOffer.amountAfterDiscount)}
            currency={currency}
          />{" "}
          {selectedOffer.periods !== 1 ? (
            <span>
              {"("}
              <Price
                value={
                  Number(discountedOffer.amountAfterDiscount) /
                  selectedOffer.periods
                }
                currency={currency}
                roundToSecondNumber
              />{" "}
              / {translate("month")}
              {")"}
            </span>
          ) : null}
        </span>
      </>
    );
  }

  extractPaymentMethodsFromOffer(offer): PaymentMethodMetaData {
    if (!offer.subscription_methods || isEmpty(offer.subscription_methods)) {
      return {
        paymentMethods: offer.payment_methods,
        subscriptionId: null
      };
    }

    return {
      paymentMethods: offer.subscription_methods[0].payment_methods,
      subscriptionId: offer.subscription_methods[0].id
    };
  }

  render() {
    const {
      translate,
      locale,
      match: {
        params: { offerId }
      },
      paymentDetails,
      loading,
      paymentFormLoading,
      addInfoAlert,
      finalizePayment,
      globalConfig: { offerUrl },
      userWasLoggedIn
    } = this.props;

    const userIsLoggedIn = this.getUserIsLoggedIn();

    if (!userIsLoggedIn) {
      const urlPathToRedirectTo = userWasLoggedIn
        ? "fe.loginWithRedirect"
        : "fe.registerWithRedirect";

      const infoAlertContent = userWasLoggedIn
        ? "You have to log in to continue"
        : "You have to register to continue";

      addInfoAlert({
        title: translate(infoAlertContent)
      });

      return (
        <Redirect
          to={UrlProvider.getUrl(urlPathToRedirectTo, {
            locale,
            redirectUrl: window.location.pathname
          })}
        />
      );
    }

    const {
      cardModel,
      paymentMethod,
      paymentMethodAlias,
      currentPaymentMethod
    } = this.state;

    const { currency } = paymentDetails;

    let selectedOffer = null;
    let offerOutdated = false;

    if (paymentDetails && !isEmpty(paymentDetails) && paymentDetails.offers) {
      selectedOffer = paymentDetails.offers.find(
        offer => offer.id === Number(offerId)
      );

      if (!selectedOffer) {
        offerOutdated = true;
        selectedOffer = {};
      }
    }

    if (loading || isEmpty(paymentDetails) || !paymentDetails.offers) {
      return <Loader />;
    }

    let isRecurringPaymentMethod =
      [
        PAYMENT_METHODS.VISA,
        PAYMENT_METHODS.PAYLANE,
        PAYMENT_METHODS.PAYPAL,
        PAYMENT_METHODS.STRIPE_SUBSCRIPTION
      ].indexOf(paymentMethod) !== -1;

    if (offerOutdated) {
      setTimeout(() => {
        window.open(offerUrl || config.defaultPremiumOfferUrl, "_self");
      }, 3000);
    }

    const {
      paymentMethods: offerPaymentMethods,
      subscriptionId
    } = this.extractPaymentMethodsFromOffer(selectedOffer);

    const discounted = hasDiscount(selectedOffer);

    const offerDescription = selectedOffer?.description;
    const specialOfferDescription = selectedOffer?.special_offer_description;

    const hasOfferDescription = hasDescription(offerDescription);
    const hasSpecialOfferDescription = hasDescription(specialOfferDescription);

    return (
      <div className="payment-form">
        <PageTitle title={translate("Payment")} />
        {offerOutdated ? (
          <ContentSection>
            <p>
              <SquaberLink to={offerUrl || config.defaultPremiumOfferUrl}>
                {translate(
                  "The offer is no longer available, take advantage of current offers"
                )}
              </SquaberLink>
            </p>
          </ContentSection>
        ) : (
          <div className={"wrapper"}>
            <ContentSection>
              <div className="selected-offer-details-wrapper">
                <Confirmation
                  selectedOffer={selectedOffer}
                  offerPaymentMethods={offerPaymentMethods}
                  paymentMethods={this.paymentMethods}
                  paymentMethod={paymentMethod}
                  paymentMethodAlias={paymentMethodAlias}
                  currentPaymentMethod={currentPaymentMethod}
                  paymentDetails={paymentDetails}
                  setPaymentMethod={this.setPaymentMethod}
                  isRecurringPaymentMethod={isRecurringPaymentMethod}
                  currency={currency}
                  paymentFormLoading={paymentFormLoading}
                  cardModel={cardModel}
                  offerId={offerId}
                  finalizePayment={finalizePayment}
                  subscriptionId={subscriptionId}
                  small
                  isSideButton
                />
              </div>
              <div className="summary">
                <h1>{translate("Summary")}</h1>
                <div className="selected-offer-details">
                  <span className="label">{translate("Selected option")}:</span>
                  <span className="offer-detail">
                    {translate("Squaber for %{days} days", {
                      days: selectedOffer.periods * 30
                    })}
                  </span>
                  <span className="label">{translate("Price")}:</span>
                  <span
                    className={classNames("offer-detail", {
                      discounted
                    })}
                  >
                    <Price
                      value={Number(selectedOffer.amount)}
                      currency={currency}
                    />{" "}
                    {selectedOffer.periods !== 1 ? (
                      <span>
                        {"("}
                        <Price
                          value={
                            Number(selectedOffer.amount) / selectedOffer.periods
                          }
                          currency={currency}
                          roundToSecondNumber
                        />{" "}
                        / {translate("month")}
                        {")"}
                      </span>
                    ) : null}
                  </span>
                  {discounted ? this.getDiscountSummary(selectedOffer) : null}
                </div>
              </div>
            </ContentSection>
            {hasSpecialOfferDescription || hasOfferDescription ? (
              <div>
                <ContentSection>
                  <div
                    className={
                      "selected-offer-description selected-offer-description_first"
                    }
                    dangerouslySetInnerHTML={{
                      __html: getOfferDescription([
                        specialOfferDescription,
                        offerDescription
                      ])
                    }}
                  />
                  <Confirmation
                    selectedOffer={selectedOffer}
                    paymentMethods={this.paymentMethods}
                    offerPaymentMethods={offerPaymentMethods}
                    paymentMethod={paymentMethod}
                    paymentMethodAlias={paymentMethodAlias}
                    currentPaymentMethod={currentPaymentMethod}
                    paymentDetails={paymentDetails}
                    setPaymentMethod={this.setPaymentMethod}
                    isRecurringPaymentMethod={isRecurringPaymentMethod}
                    currency={currency}
                    paymentFormLoading={paymentFormLoading}
                    cardModel={cardModel}
                    offerId={offerId}
                    finalizePayment={finalizePayment}
                    subscriptionId={subscriptionId}
                    small
                  />
                </ContentSection>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withAddAlert,
  withUserData,
  withPaymentForm,
  withPaymentDetails,
  withGlobalConfigFields([globalConfigFields.OFFER_URL])
)(PaymentForm);
